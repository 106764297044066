import { AppSubTypePath, ItemType } from '../types'

import type * as Types from './types'
import type { CompanyPayload } from '~/modules/marketGuide/types'
import type { JobPayload } from '~/modules/jobs/types'
import type { FamilyAdsPayload } from '~/modules/family/types'
import { AvailableApps } from '~/config/apps'

const metaResolver: Types.MetaResolver = (params, location) => {
    const metaPayload = {
        title: '',
        description: '',
        charset: 'utf-8',
        viewport: 'width=device-width,initial-scale=1'
    } as Types.MetaPayload

    if (params) {
        const {
            _type,
            payload,
            app,
            scope
        } = params

        if (app) {
            if (app.name) {
                metaPayload.title = app.name
            }
            
            if (app.description) {
                if (app.id === AvailableApps.Jobs) {
                    metaPayload.description = app.description.replace("%scope", scope.name.concat("'s"))
                }
                else if (app.id === AvailableApps.Family) {
                    // Different meta data for fira/minnas
                    const extension = location.pathname.match(AppSubTypePath.Fira) ? 
                        app.familyExtension : 
                        app.memorialExtension
                    metaPayload.title = metaPayload.title.concat(extension ?? '')

                    metaPayload.description = location.pathname.match(AppSubTypePath.Fira) ? 
                        app.familyDescription ?? '' :
                        app.memorialDescription ?? ''
                    metaPayload.description = metaPayload.description.replace("%geoplace", scope.defaultCounty)
                }
                else {
                    metaPayload.description = app.description
                }
            }
        }
    
        if (scope?.name) {
            metaPayload.title = `${metaPayload.title ? `${metaPayload.title} - ` : ''}${scope.name}`
        }

        if (!metaPayload.title) {
            metaPayload.title = 'GotaMedia'
        }
    
        if (!metaPayload.description) {
            metaPayload.description = 'GotaMedia Web App'
        }
    
        if (_type && payload) {
            switch(_type) {
                case ItemType.Company: {
                    const data = payload as CompanyPayload
    
                    if (data.name) {
                        metaPayload.title = `${metaPayload.title} / ${data.name}`
                    }
    
                    break
                }
        
                case ItemType.Job: {
                    const data = payload as JobPayload
    
                    if (data.companyName) {
                        metaPayload.title = `${metaPayload.title} / ${data.companyName}`
                        metaPayload.description = `${metaPayload.description} / ${data.companyName}`
                    }
    
                    break
                }
        
                case ItemType.BirthdayAd: {
                    const data = payload as FamilyAdsPayload
    
                    const title = data?.ads?.[0]?.title
    
                    if (title) {
                        metaPayload.title = `${metaPayload.title} / ${title}`
                    }
    
                    break
                }
    
                case ItemType.MemorialAd: {
                    const data = payload as FamilyAdsPayload
    
                    const title = data?.ads?.[0]?.title
    
                    if (title) {
                        metaPayload.title = `${metaPayload.title} / ${title}`
                    }
    
                    break
                }
            }
        }
    }

    return metaPayload
}

export default metaResolver